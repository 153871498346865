<template>
  <header class="mx-auto bg-white relative z-50">
    <div v-if="!isMobile()" class="container mx-auto flex items-center py-4 h-28">
      <nav class="container mx-auto flex items-center justify-between text-base">
        <div class="flex items-center items-justify">
          <router-link to="/">
            <img :src="LogoSrc" alt="Logo" class="py-2" />
          </router-link>
        </div>
        <!-- Navigation Links -->
        <ul class="mx-auto flex items-center justify-between font-medium text-[#63666a]">
          <li class="relative group">
            <router-link to="/" aria-label="Home" title="Home">Home</router-link>
          </li>

          <!-- "About" with Dropdown -->
          <li class="relative group">
            <router-link to="/about" aria-label="About" title="About">About</router-link>
            <ul class="absolute left-0 hidden group-hover bg-vekBlue text-white rounded-[22px] shadow-lg mt-2 z-50">
              <router-link to="/about">
                <h4 class="px-4">What is Veklury?</h4>
              </router-link>
              <li class="px-2">
                <router-link :to="{ path: '/about', hash: '#history' }" class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">History of Veklury</span>
                </router-link>
              </li>
              <li class="px-2">
                <router-link :to="{ path: '/about', hash: '#indications' }" class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Indications</span>
                </router-link>
              </li>
              <li class="px-2">
                <router-link :to="{ path: '/about', hash: '#adverse-event' }"
                  class="flex items-center text-left 2xl:whitespace-nowrap">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Adverse Event Profiles and Monitoring
                    Indications</span>
                </router-link>
              </li>
              <router-link :to="{ path: '/about', hash: '#where-used' }">
                <h4 class="px-4">Where Is It Used?</h4>
              </router-link>
              <router-link :to="{ path: '/about', hash: '#action-mechanism' }">
                <h4 class="px-4">Mechanism Of Action?</h4>
              </router-link>
            </ul>
          </li>

          <li>
            <router-link to="/clinical-studies" aria-label="Clinical Studies" title="Clinical Studies">Clinical
              Studies</router-link>
          </li>

          <li>
            <router-link to="/media" aria-label="Multimedia" title="Multimedia">Multimedia</router-link>
          </li>

          <!-- "Side Effect and Key Data" with Dropdown -->
          <li class="relative group">
            <router-link to="/side-effects" aria-label="Side effects & key data" title="Side effects & key data">Side
              Effects & Key Data</router-link>
            <ul class="absolute left-0 hidden group-hover bg-vekBlue text-white rounded-[22px] shadow-lg mt-2 z-50">
              <router-link to="/side-effects">
                <h4 class="px-4">Side Effects</h4>
              </router-link>
              <li class="px-2">
                <router-link :to="{ path: '/side-effects', hash: '#adverse-reactions' }"
                  class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Description of selected adverse
                    reactions</span>
                </router-link>
              </li>
              <li class="px-2">
                <router-link :to="{ path: '/side-effects', hash: '#actt1-trial' }"
                  class="flex items-center text-left 2xl:whitespace-nowrap">
                  <i class="fas fa-arrow-right mr-2.5"></i>&nbsp;<span class="pb-px">Adverse events in the ACTT-1 trial
                    (all severities)</span>
                </router-link>
              </li>
              <li class="px-2">
                <router-link :to="{ path: '/side-effects', hash: '#paediatric-safety' }"
                  class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Safety in paediatric population</span>
                </router-link>
              </li>
              <li class="px-2">
                <router-link :to="{ path: '/side-effects', hash: '#contraindications' }"
                  class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Contraindications and Drug-Drug
                    Interactions</span>
                </router-link>
              </li>
              <li class="px-2">
                <router-link :to="{ path: '/side-effects', hash: '#precautions' }" class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Special Warnings and Precautions</span>
                </router-link>
              </li>
              <router-link :to="{ path: '/side-effects', hash: '#key-data' }">
                <h4 class="px-4 mt-[10px]">Key Data</h4>
              </router-link>
              <li class="px-2">
                <router-link :to="{ path: '/side-effects', hash: '#storage' }" class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Storage</span>
                </router-link>
              </li>
              <li class="px-2">
                <router-link :to="{ path: '/side-effects', hash: '#posology' }" class="flex items-center text-left">
                  <i class="fas fa-arrow-right mr-2.5"></i><span class="pb-px">Posology</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a href="mailto:ukmed.info@gilead.com"
              class="no-underline rounded-[25px] py-2.5 px-8 font-medium text-white shadow-md bg-purple-700 hover:bg-white hover:text-purple-700">
              Contact Us
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="inline-block size-6">
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path
                  d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div v-else class="container mx-auto antialiased max-w-full p-0">
      <nav class="contents bg-white text-base text-white uppercase">
        <!-- Mobile Header (in the flow of the document, not fixed) -->
        <div class="flex items-center justify-between p-5 bg-white shadow-lg">
          <!-- Logo -->
          <router-link to="/">
            <img :src="LogoSrc" alt="Logo" class="h-12" />
          </router-link>

          <!-- Hamburger Icon (Menu Open / Close Toggle) -->
          <button @click="toggle" class="flex items-center px-3 py-2 text-black">
            <svg v-if="!isMenuOpen" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg v-if="isMenuOpen" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Mobile Menu (Appears beneath the header in the flow of the document) -->
        <transition name="slide-down">
          <div v-if="isMenuOpen"
            class="mobile-menu bg-blue-500 text-white z-30 flex flex-col items-center space-y-6 py-8 text-2xl leading-[3.5rem] w-full">
            <router-link to="/" aria-label="Home" @click="toggle">Home</router-link>
            <router-link to="/about" aria-label="About" @click="toggle">About</router-link>
            <router-link to="/case-studies" aria-label="Clinical Studies" @click="toggle">Clinical Studies</router-link>
            <router-link to="/media" aria-label="Multimedia" @click="toggle">Multimedia</router-link>
            <router-link to="/side-effects" aria-label="Side effects & key data" @click="toggle">Side Effects & Key
              Data</router-link>
            <a href="mailto:ukmed.info@gilead.com"
              class="no-underline rounded-full py-2.5 px-8 font-bold text-purple-700 bg-white hover:bg-white hover:text-purple-700 leading-[1.75rem]">
              Contact Us
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="inline-block w-5 h-5 ml-20 mb-[3px]">
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path
                  d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
              </svg>
            </a>
          </div>
        </transition>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  computed: {
    LogoSrc() {
      return 'images/veklury-logo.png';
    }
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isMenuOpen = !this.isMenuOpen
    },
    isMobile() {
      return screen.width <= 1200;
    }
  },
}
</script>

<style scoped>
/* Menu styling */
nav ul li a {
  display: block;
  padding: 0.375rem 0.75rem;
  margin-right: 0.25rem;
  border-radius: 25px;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

nav ul li a:hover,
nav ul li a.active,
nav ul li a.exact-active {
  background: #0075CF;
  color: #FFFFFF;
  border-radius: 25px;
  margin-top: 5px;
}

/* Added space between link and dropdown */
.group ul {
  margin-top: 10px;
}

.group ul::before {
  position: absolute;
  top: -15px;
  display: block;
  content: "";
  height: 20px;
  width: 100%;
}

.group ul h4 {
  font-family: "proxima-nova";
  font-size: 30px;
  line-height: 37px;
  font-weight: 800;
  color: #FFFFFF;
  text-transform: capitalize;
  text-align: left;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

/* Dropdown menu styling */
.group ul li a {
  margin-right: 0.25rem;
  font-size: 25px;
  line-height: 30px;
  text-transform: capitalize;
}

/* Ensure dropdown overlays the content with z-index */
.group-hover {
  display: none;
}

.group:hover .group-hover,
.group-hover:hover {
  display: block;
}

/* Dropdown styling */
nav ul li ul {
  min-width: 250px;
  padding-top: 5px;
  padding-bottom: 15px;
}

/* Ensure dropdown overlays with z-index */
.header-dropdown {
  position: absolute;
  z-index: 9999;
}

/* Fixed header with z-index */
.fixed.top-0.left-0.right-0 {
  background-color: #fff;
  /* Ensure background matches design */
  z-index: 50;
  /* Keep header on top */
  height: 4rem;
  /* Adjust height as needed */
}

/* Ensure the mobile menu overlays content below the header */
.mobile-menu {
  position: absolute;
  /* No fixed positioning, so it sits in the flow */
  background-color: #0075CF;
  /* Blue background */
  z-index: 30;
  /* Ensures it is still above page content */
  width: 100%;
}

.flex.items-center.space-y-6 {
  text-align: center;
  font-weight: bold;
}

button svg {
  stroke-width: 2.5px;
}

a:hover {
  transition: 0.3s ease;
}

/* Slide-down animation for mobile menu */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
