<template>
  <div id="app">
    <BannerComponent v-if="showHeader" :OpenURL="openFrame" :TooltipMsg="tooltipMsg" />
    <HeaderComponent v-if="showHeader" />
    <router-view class="content" />
    <FooterComponent v-if="showFooter" :OpenURL="openFrame" :TooltipMsg="tooltipMsg" />
    <!-- iFrame -->
    <div v-if="iframeVisible"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center w-full h-full z-50">
      <div class="relative bg-opacity-100 shadow-lg text-xl w-full max-w-5xl aspect-video">
        <div class="grid grid-rows-[auto,1fr] gap-2 w-full h-full">
          <!-- Close button at the top-right corner -->
          <div class="relative flex w-full h-11 mb-2">
            <button @click="closeFrame"
              class="absolute top-0 right-0 aspect-square p-3 text-white bg-vekBlue">✕</button>
          </div>
          <!-- Iframe container -->
          <div class="relative bg-white overflow-hidden w-full h-full">
            <iframe :src="iframeSrc" class="w-full h-full" frameborder="0"></iframe>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import BannerComponent from './components/banner.vue';
import HeaderComponent from './components/header.vue';
import FooterComponent from './components/footer.vue';


export default {
  name: 'App',
  mounted() {
    // Ensure the role cookie is checked when the app is mounted
    this.checkRoleFromCookie();
  },
  data() {
    return {
      tooltipMsg: "This link will take you to a non-Gilead website. Gilead does not recommend, endorse or accept liability for sites controlled by third parties.",
      iframeSrc: "",
      iframeVisible: false,
      noHeaderFooterRoutes: ['/gateway', '/public-member', '/public-terms', '/login'],
    }
  },
  created() {
    this.roleSelected = false;
    this.IsGeneral = false;
    this.iframeVisible = false;
  },
  components: {
    BannerComponent,
    HeaderComponent,
    FooterComponent,
  },
  computed: {
    showHeader() {
      // Return false if the current route is in the noHeaderFooterRoutes list
      return !this.noHeaderFooterRoutes.includes(this.$route.path);
    },
    showFooter() {
      // Return false if the current route is in the noHeaderFooterRoutes list
      return !this.noHeaderFooterRoutes.includes(this.$route.path);
    },
  },
  provide() {
    return {
      handleSelectedRole: this.handleSelectedRole,
      openFrame: this.openFrame,
      closeFrame: this.closeFrame
    };
  },
  methods: {
    handleSelectedRole(role) {
      // Set the role in cookies and update local state
      this.$cookies.set('userRole', role, '1d'); // Set cookie for 1 day
      this.checkRoleFromCookie();

      if (role === 'healthcare') {
        this.$router.push({ name: 'home' });
      } else {
        this.$router.push({ name: 'public-member' });
      }

      return;
    },
    checkRoleFromCookie() {
      // Retrieve the role from the cookie and set state
      const userRole = this.$cookies.get('userRole');
      if (userRole) {
        localStorage.setItem('userRole', userRole)
      }
    },
    openFrame: function (url) {
      this.iframeSrc = url
      this.iframeVisible = true;
    },
    closeFrame() {
      this.iframeVisible = false;
      this.iframeSrc = '';
    }
  }
};
</script>

<style>
@import './assets/adobe-proxima-nova.css';

#app {
  font-family: "proxima-nova",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #ffffff;
  color: #375172;
}
</style>
